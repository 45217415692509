import axios from '@/plugins/axios'

/**
 * Create advertiser
 */
const createAdvertiser = async ({
  country,
  type,
  address = null,
  name = null,
  // eslint-disable-next-line camelcase
  advertiser_document = null,
  currency,
  // eslint-disable-next-line camelcase
  billing_profile = null,
  website = null,
  // eslint-disable-next-line camelcase
  category_id = null,
  // eslint-disable-next-line camelcase
  user_name,
  // eslint-disable-next-line camelcase
  user_email,
  // eslint-disable-next-line camelcase
  user_phone,
  // eslint-disable-next-line camelcase
  company_name,
  // eslint-disable-next-line camelcase
  company_fantasy_name,
  // eslint-disable-next-line camelcase
  company_document,
  // eslint-disable-next-line camelcase
  company_address = null
}) => {
  try {
    const form = new FormData()
    form.append('country', country)
    form.append('type', type)
    if (address) {
      Object.keys(address).forEach(key => {
        form.append(`address[${key}]`, address[key])
      })
    }
    form.append('name', name)
    form.append('billing_profile', billing_profile)
    form.append('advertiser_document', advertiser_document)
    form.append('currency', currency)
    form.append('website', website)
    form.append('category_id', category_id)
    form.append('user_name', user_name)
    form.append('user_email', user_email)
    form.append('user_phone', user_phone.replace(/\s+/g, '').replace('+', ''))
    form.append('company_name', company_name)
    form.append('company_fantasy_name', company_fantasy_name)
    form.append('company_document', company_document)
    // eslint-disable-next-line camelcase
    if (company_address) {
      Object.keys(company_address).forEach(key => {
        form.append(`company_address[${key}]`, company_address[key])
      })
    }

    const response = await axios.post('admin/advertisers', form)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get advertiser
 */
const getAdvertiser = async (id) => {
  try {
    const response = await axios.get(`admin/advertisers/${id}`)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get advertisers
*/
const getAdvertisers = async ({
  // eslint-disable-next-line camelcase
  company_id = null,
  page = 1,
  search = null,
  // eslint-disable-next-line camelcase
  with_orders = false,
  // eslint-disable-next-line camelcase
  without_orders = false,
  status = null
}) => {
  try {
    const params = {
      page: page
    }
    if (search) params.search = search
    // eslint-disable-next-line camelcase
    if (company_id) params.company_id = company_id
    // eslint-disable-next-line camelcase
    if (with_orders) params.with_orders = 1
    // eslint-disable-next-line camelcase
    if (without_orders) params.without_orders = 1
    if (status) params.status = status
    const response = await axios.get('admin/advertisers', {
      params: params
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get used coupons
*/
const getUsedCouponsByAdvertiser = async (id) => {
  try {
    const response = await axios.get(`admin/advertisers/${id}/coupons`)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Update advertiser
 */
const updateAdvertiser = async (id, {
  country,
  address = null,
  name = null,
  // eslint-disable-next-line camelcase
  advertiser_document = null,
  // eslint-disable-next-line camelcase
  billing_profile = null,
  website = null,
  // eslint-disable-next-line camelcase
  category_id = null
}) => {
  try {
    const form = new FormData()
    form.append('country', country)
    if (address) {
      Object.keys(address).forEach(key => {
        form.append(`address[${key}]`, address[key])
      })
    }
    form.append('name', name)
    form.append('advertiser_document', advertiser_document)
    form.append('billing_profile', billing_profile)
    form.append('website', website)
    form.append('category_id', category_id)

    form.append('_method', 'PUT')
    const response = await axios.post(`admin/advertisers/${id}`, form)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/*
 * Transfer amount
 */
const transferAmount = async ({
  advertiserId,
  recipientId,
  amount
}) => {
  try {
    const values = {
      advertiser_id: advertiserId,
      recipient_id: recipientId,
      amount: amount
    }
    const response = await axios.post('admin/advertisers/transfer-amount', values)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/*
 * Withdraw amount
 */
const withdrawAmount = async ({
  advertiserId,
  amount,
  reason
}) => {
  try {
    const values = {
      advertiser_id: advertiserId,
      amount: amount,
      reason: reason
    }
    const response = await axios.post('admin/advertisers/withdraw-amount', values)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  createAdvertiser,
  getAdvertiser,
  getAdvertisers,
  getUsedCouponsByAdvertiser,
  transferAmount,
  updateAdvertiser,
  withdrawAmount
}
